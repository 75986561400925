import React, { useCallback, useEffect, useState } from "react";
import "../style/global.css";
import err from "../img/404 final -02.svg";
import { Button } from "@mui/material";
import "../style/components.css";

export default function Empty(props) {
  const [message, setMessage] = useState(false);

  const getMessage = useCallback(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("message")) {
      setMessage(urlParams.get("message"));
    } else {
      setMessage(false);
    }
  }, []);

  useEffect(() => {
    getMessage();
  }, [getMessage]);

  return (
    <div className="container-empty">
      <div className="empty-content">
        <p className="Title-empty">
          page introuvable
          {/* {message
              ? message
              : props.message
              ? props.message
              : "Nous sommes dèsolès, la page que vous cherchez est introuvable."} */}
        </p>
        <img src={err} alt="err" id="err" />
        <p className="Text-empty">
          Nous sommes dèsolès, la page que vous cherchez est introuvable
        </p>
      </div>
      <Button
        variant="contained"
        //   color="blue"
        className="Btn-acceuil-empty"
        onClick={() => (window.location = "/")}
      >
        Retour à l'accueil{" "}
      </Button>
    </div>
  );
}
