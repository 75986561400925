import React, { useState, useRef } from "react";
import { Button, Dialog, DialogActions, DialogContent, TextField, IconButton, Paper, Typography, Tooltip, MenuItem, ListItemIcon } from "@mui/material";
import Iconn from '@mdi/react';
import { mdiCloseCircle, mdiLockReset } from "@mdi/js";
import '../style/login.css';
import '../style/global.css';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Draggable from "react-draggable";
import Icon from "@mdi/react";
import { paperPropsSmaller } from "../style/muiStyle";
import { axiosPostAction, axiosResponse } from "../Gfunc";
import { useAlert } from "../contexts/alertContext";


const DialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <MuiDialogTitle {...other}>
      <Typography>{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" onClick={onClose}>
          <Iconn path={mdiCloseCircle} color="white" size="20px" />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
};

const PaperComponent = (props) => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
};



export default function ResetPwd(props) {
  const { showAlert } = useAlert();
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(false);




  const handleClickOpen = () => {
    setOpen(true);
    setPassword("");
    setConfirmPassword("");
  };

  const handleClose = () => { setOpen(false); };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword && password !== "") {

      e.preventDefault();
      var formData = new FormData();
      formData.append("att", "password");
      formData.append("id", localStorage.getItem("uid"));
      formData.append("val", password);
      const response = await axiosPostAction("_usr.php", "upd", formData);
      if (response.data.err && response.data.err !== "-1") {
        showAlert(response.data.err, "error");
      } else {
        axiosResponse(response, () => {
          if (response.data.jData) {
            showAlert(response.data.err, "error");
          }
        });
      }
    } else {
      setError(true);
    }
  };

  return (
    <>

      {<MenuItem onClick={handleClickOpen}>
        <ListItemIcon><Icon path={mdiLockReset} color="grey" size="22px" /></ListItemIcon>
        Changer mot de passe
      </MenuItem>}
      <Dialog
        open={open}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        PaperProps={paperPropsSmaller}
      >
        <DialogTitle> Réinitialiser le mot de passe </DialogTitle>
        <form ref={useRef(null)} id='resetPass' onSubmit={handleSubmit}>
          <DialogContent className="centered-container">
            <TextField
              fullWidth
              id="pass"
              name="pass"
              error={error && (password === "" || password !== confirmPassword)}
              helperText={error && (password === "" ? "Veuillez saisir un nouveau mot de passe!" : "Les mots de passe ne correspondent pas!")}
              label='Nouveau mot de passe'
              type='password'
              value={password}
              variant='outlined'
              onChange={(e) => { setPassword(e.target.value); setError(false); }}
              required
            //autoFocus
            />
            <TextField
              fullWidth
              id="conf"
              name="confPass"
              error={error && (confirmPassword === "" || password !== confirmPassword)}
              helperText={error && (confirmPassword === "" ? "Veuillez confirmer votre nouveau mot de passe!" : "Les mots de passe ne correspondent pas!")}
              label='Confirmer le mot de passe'
              type='password'
              value={confirmPassword}
              variant='outlined'
              onChange={(e) => { setConfirmPassword(e.target.value); setError(false); }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button className='Button-submit' variant="contained" type='submit' form='resetPass' color="primary" startIcon={<Iconn path={mdiLockReset} size={1} />}>Réinitialiser</Button>
            <Button variant="contained" onClick={handleClose} className="Button-close">FERMER</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
