import { createTheme } from "@mui/material";

export const myPalette = {
  primary: { light: "#033366", main: "#012244", dark: "#001b33" }, //dark blue
  secondary: { light: "#FF3333", main: "#ED0000", dark: "#B30000" }, //gris
  grey: { light: "#f6f6f6", main: "#ccc", dark: "#434343" },
  yellow: { light: "#FFFEA9", main: "#fbdf46", dark: "#f7bd4d" }, //yello
  //error: {main: "#e53935"},//rouge foncé
  error: { main: "rgba(255, 0, 0, 0.6)" }, //rouge foncé
  //success: {main: "#388e3c"},//vert
  success: { main: "rgba(46, 125, 50,0.7)" }, //vert
  bleu: { light: "#c5cae9", main: "#3f51b5", dark: "#0e073a" },
  background: { default: "#F4F6F8" },
};

export const theme = createTheme({
  palette: myPalette,
  typography: {
    fontFamily: "Poppins, sans-serif",
    button: {
      fontSize: 13,
      fontWeight: "bold",
    },
    h6: {
      fontSize: "16px",
      fontWeight: "600",
    },
  },

  zIndex: {
    mobilestepper: 1000,
    fab: 1050,
    speeddial: 1050,
    appbar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    MuiTableContainer: {
      root: {
        height: "400px !important", // Changer la hauteur selon vos besoins
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          //height: '36px', // Set the desired height here
          fontSize: "16px",
          padding: "0 !important",
          paddingLeft: "10px !important",
          MuiIconButton: {
            padding: "0px",
          },
        },
        input: {
          // height: '32px !important',
          margin: "2px !important",
          padding: " 2px 0px !important",
          paddingLeft: " 8px",
          backgroundColor: "white !important",
        },
      },
    },

    // MuiSelect: {
    //   styleOverrides: {
    //     select: {
    //       height: "38px", // Set the desired height here
    //     },
    //   },
    // },
    /*MuiTextField: {
      styleOverrides: {
          input: {
            padding:"0px !important",
            height: '38px', // Set the desired height here
          },
      },
    },*/
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          top: "-12px !important",
        },
        shrink: {
          transform: "translate(14px, 0px) scale(0.75)",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          height: "32px", // Adjust the height of buttons
        },
      },
      variants: [
        {
          props: { color: "primary" },
          style: {
            "&:hover": {
              backgroundColor: (theme) =>
                theme.palette.primary.light + " !important",
            },
          },
        },
      ],
    },

    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       backgroundColor: "white ", ///fella
    //     },
    //   },
    // },
    MuiFormControl: {
      styleOverrides: {
        root: {
          backgroundColor: "white !important", ///fella
          marginTop: "0px",
          marginBottom: "0px",
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          overflow: "hidden !important",
          borderRadius: "8px",
          maxWidth: "none !important", // Adjust the max width as needed
          maxHeight: "100vh",
          background: "#F9FAFB",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          backgroundColor: "#F9FAFB",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          background: myPalette.primary.dark,
          height: "30px",
          padding: "8px 16px",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between ",
        },
      },
      title: {
        flex: "1",
        textAlign: "center",
        margin: "auto", // Set margin to auto
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          background: "#F9FAFB",
          padding: "16px 24px !important",
          color: "black",
          display: "flex",
          alignItems: "center",

          //justifyContent:'center' ////fella
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Adjust the border radius for Paper components
          // display: "inline-block",
          // width: "fit-content", /////imene
        },
      },
    },

    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#999 ",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#999 ",
        },
      },
    },

    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "12px", // added by fella
        },
      },
    },

    MuiCardMedia: {
      styleOverrides: {
        root: {
          display: "flex",
          backgroundsize: "cover",
          backgroundrepeat: "no-repeat",
          backgroundposition: " center", // added by fella
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Adjust the border radius for App Bar
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Adjust the border radius for Cards
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "9px",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          MuiButton: {
            root: {
              lineheight: "0 !important",
              alignItems: "center",
            },
          },
        },
      },
    },
  },
  overrides: {
    MuiButton: {
      root: {
        height: "32px", // Adjust the height of buttons
      },
      containedSecondary: {
        color: "white",
        backgroundColor: "#F18C20", // Couleur du fond du bouton secondaire
        "&:hover": {
          backgroundColor: "#F18C20", // Couleur du fond du bouton secondaire au survol
        },
      },
    },
    // paperAutocomplete: {
    //   width: "max-content !important",
    // },
    MuiAutocomplete: {
      root: {
        padding: "0",
        paddingLeft: "10px !important",
        width: "50px !important",
      },
      // MuiPaper: {
      //   styleOverrides: {
      //     root: {
      //       background: "red",
      //       borderRadius: "8px", // Adjust the border radius for Paper components
      //       display: "inline-block",
      //       width: "fit-content",
      //       ////imene
      //     },
      //   },
      // },
      MuiPopper: {
        styleOverrides: {
          popper: {
            width: "max-content !important",
          },
        },
      },

      // paper: {
      //   overflow: "visible",
      //   whiteSpace: "nowrap !important",
      //   fontSize: "13px !important",
      //   color: "red",
      // },
      option: {
        background: "white !important",
        '&[data-focus="true"]': {
          backgroundColor: "#eee !important",
        },
      },
      tag: {
        margin: "6px",
      },
    },

    MuiOutlinedInput: {
      root: {
        //height: '36px', // Set the desired height here
        fontSize: "16px",
        padding: "0 ",
        paddingLeft: "10px !important",
      },
      input: {
        // height: '32px !important',
        margin: "2px !important",
        padding: " 2px 0px !important",
        paddingLeft: " 8px !important",
      },
    },
    MuiInputLabel: {
      outlined: {
        top: "-12px !important",
      },
      shrink: {
        transform: "translate(14px, 0px) scale(0.75)",
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "9px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        MuiTableCell: {
          backgroundColor: myPalette.primary,
        },
      },
    },

    /*MuiButton: {
      root: {
        fontFamily: 'soho',
        fontSize: '13px',
        textTransform: 'none', // Optionnel : désactive la transformation du texte en majuscules
      },
      containedPrimary: {
        color: 'white',
        backgroundColor: '#0270B1', // Couleur du fond du bouton primaire
        '&:hover': {
          backgroundColor: '#0270B1', // Couleur du fond du bouton primaire au survol
        },
      },
      outlinedPrimary: {
        color: '#0270B1', // Couleur du texte du bouton primaire
        borderColor: '#0270B1', // Couleur de la bordure du bouton primaire
        '&:hover': {
          color: 'white',
          backgroundColor: '#0270B1', // Couleur du fond du bouton primaire au survol
          borderColor: '#0270B1', // Couleur de la bordure du bouton primaire au survol
        },
      },
      containedSecondary: {
        color: 'white',
        backgroundColor: '#F18C20', // Couleur du fond du bouton secondaire
        '&:hover': {
          backgroundColor: '#F18C20', // Couleur du fond du bouton secondaire au survol
        },
      },
      outlinedSecondary: {
        color: '#F18C20', // Couleur du texte du bouton secondaire
        borderColor: '#F18C20', // Couleur de la bordure du bouton secondaire
        '&:hover': {
          color: 'white',
          backgroundColor: '#F18C20', // Couleur du fond du bouton secondaire au survol
          borderColor: '#F18C20', // Couleur de la bordure du bouton secondaire au survol
        },
      },
    },*/
  },
});
