import { ReactComponent as Cash } from "../img/icons/tresorerie.svg";
import { ReactComponent as Products } from "../img/icons/produit.svg";
import { ReactComponent as Prelevement } from "../img/icons/ooredoo.svg";
import { ReactComponent as RC } from "../img/icons/registre commerce .svg";
import { ReactComponent as COD } from "../img/icons/grossiste.svg";
import { ReactComponent as R } from "../img/icons/retail.svg";
import { ReactComponent as Frns } from "../img/icons/fournisseur.svg";
import { ReactComponent as Stromi } from "../img/icons/storm strock.svg";
import { ReactComponent as Voucher } from "../img/icons/voucher fill.svg";
import { ReactComponent as Scratch } from "../img/icons/scratch.svg";
import { ReactComponent as Sim } from "../img/icons/sim.svg";
import { ReactComponent as Prv } from "../img/icons/prelevement2.svg";
import { ReactComponent as Stock } from "../img/icons/stock-01.svg";
import { ReactComponent as Chq } from "../img/icons/cheque bancaire-01.svg";
import { ReactComponent as Vir } from "../img/icons/virement bancaire-01.svg";
import { ReactComponent as Thermalpaper } from "../img/icons/thermo papr-01-01.svg";

import React from "react";

export function GetIconName(menu) {
  switch (menu) {
    case "A PROPOS":
    case "A propos":
      return "mdiInformation";
    case "ENTREPRISES":
    case "Entreprises":
      return "mdiCity";
    case "Employés":
      return "mdiAccountTie";
    case "Contrats":
      return "mdiFileDocumentEdit";
    case "Postes":
      return "mdiBriefcase";
    case "Unités":
      return "mdiOfficeBuilding";
    case "Banques":
      return "mdiBank";
    case "Conventions":
      return "mdiHumanCapacityIncrease";
    case "Signataires":
      return "mdiDrawPen";
    case "ADMIN.":
      return "mdiSecurity";
    case "Privilèges":
      return "mdiStarCheck";
    case "Roles":
      return "mdiAccountCog";
    case "Sessions":
      return "mdiAccountClock";
    case "Utilisateurs":
      return "mdiAccountMultiple";
    case "Paramètres":
      return "mdiCogs";
    case "CLIENTS":
      return "mdiAccountGroup";
    case "RC":
      return <RC style={{ width: "20px" }} fill="#fff" />;
    case "COD":
      return <COD style={{ width: "20px" }} fill="#fff" />;
    case "Retail":
      return <R style={{ width: "20px" }} fill="#fff" />;
    case "Stock":
      return <Stock style={{ width: "20px" }} fill="#fff" />;
    case "Portefeuille":
      return "mdiAccountTie";
    case "MONITORING":
      return "mdiMonitorDashboard";
    case "Lettres d'offre":
      return "mdiFilePercent";
    case "Lettres d'objectif":
      return "mdiBullseyeArrow";
    case "Prélèvements":
      return <Prv style={{ width: "20px" }} fill="#fff" />;
    case "Commandes":
      return "mdiBeakerCheck";
    case "STOCK":
      return "mdiWarehouse";
    case "Produits":
      return <Products style={{ width: "20px" }} fill="#fff" />;
    case "VOUCHER":
      return <Voucher style={{ width: "20px" }} fill="#fff" />;
    case "Papier thermique":
      return (
        <Thermalpaper style={{ width: "20px", height: "20px" }} fill="#fff" />
      );
    case "Transferts":
      return "mdiSwapHorizontalBold";

    case "TOPUP":
      return <Stromi style={{ width: "20px" }} fill="#fff" />;
    case "SCRATCH":
      return <Scratch style={{ width: "20px" }} fill="#fff" />;
    case "SIM":
      return <Sim style={{ width: "15px" }} fill="#fff" />;
    case "REFERENTIEL":
      return "mdiFolderFile";
    case "Fournisseurs":
      return <Frns style={{ width: "20px" }} fill="#fff" />;
    case "Warehouses":
      return "mdiWarehouse";
    case "REPORTS":
      return "mdiChartBarStacked";
    case "TRESORERIE":
      return <Cash style={{ width: "20px" }} fill="#fff" />;
    case "Chèques":
      return <Chq style={{ width: "20px" }} fill="#fff" />;
    case "Virements":
      return <Vir style={{ width: "20px" }} fill="#fff" />;
    case "Versements":
      return "mdiAccountCash";
    case "Dem. paiements":
      return "mdiCashClock";
    case "Paiements":
      return "mdiCashMultiple";
    case "Rapprochements":
      return "mdiCreditCardClock";
    case "Comptes":
      return "mdiAccountCreditCard";
    case "Charges":
      return "mdiHandCoin";
    case "Dashboard":
      return "mdiViewDashboard";
    case "Historique":
      return "mdiClipboardTextClock";
    default:
      return " mdiAlertCircle";
  }
}
