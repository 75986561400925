import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Icon from "@mdi/react";
// import Logo from "./../img/logos/logo_iman_full_white_red.png";
// import LogoResp from "./../img/logos/logo_iman_mini_white_red.svg";
import {
  ClickAwayListener,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import "../style/menu.css";
import { useAlert } from "../contexts/alertContext";
import AvatarCell from "../components/avatar";
import UserProfile from "../components/UserProfile";
import CustomIconButton from "../components/menu_button";
import { mdiCog, mdiLockReset, mdiLogout, mdiAccountCog } from "@mdi/js";
import ResetPwd from "./changepass";
import axios from "axios";

export default function MainMenu({ param, ...props }) {
  var index = props.menu.findIndex((obj) => obj.level === 1);
  if (index < 0) index = 0;
  const [showSubMenu, setShowSubMenu] = useState(
    window.location.pathname !== "/"
      ? window.location.pathname.split("/")[1]
      : props.menu[index].id ?? ""
  );
  //const [openMenu,handleOpenMenu]=useState(false)
  const { openMenu, handleOpenMenu } = useAlert();
  const mdi = require("@mdi/js");
  const Gfunc = require("../Gfunc");

  const menuItems = [
    <ResetPwd param={param} />,
    // { divider: true }
  ];
  const handleClick = (val) => {
    setShowSubMenu(val);
  };

  const DynamicIcon = ({ iconName }) => {
    if (typeof iconName === "string")
      return <Icon path={mdi[iconName]} size="22px" color="white" />;
    else return iconName;
  };

  const HandleResponsive = () => {
    handleOpenMenu(!openMenu);
  };
  function useWindowSize() {
    // Initial state with window size
    const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
    });

    useEffect(() => {
      // Function to update state with window size
      Gfunc.fetchParam();
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }

      // Add event listener for window resize
      window.addEventListener("resize", handleResize);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []); // Empty array ensures this effect runs only on mount and unmount

    return windowSize;
  }
  const size = useWindowSize();
  const Logout = () => {
    axios
      .get(`${param.urlService}disconnect.php`, { headers: Gfunc.getHeader() })
      .then((response) => {
        if (response.data.jData === true) {
          localStorage.removeItem("token");
          localStorage.setItem("isLoged", false);
          localStorage.clear();
          window.location = "/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (size.width < 420) handleOpenMenu(false);
      }}
    >
      <div id={!openMenu ? "sidebarResp" : "sidebar"}>
        <div
          id={openMenu ? "head-men" : "head-men2"}
          onClick={() => HandleResponsive()}
        >
          {openMenu ? (
            // <img src={Logo} alt="logo" />
            <Tooltip
              title={<UserProfile />}
              disableInteractive
              placement="right"
              arrow
            >
              <div>
                <AvatarCell
                  id={localStorage.getItem("uid")}
                  imgUrl={param.urlimg + "user/"}
                  ext={"jpg"}
                  width="70px"
                  height="70px"
                />
              </div>
            </Tooltip>
          ) : (
            // <img src={LogoResp} alt="logo" />
            <Tooltip
              title={<UserProfile />}
              disableInteractive
              placement="right"
              arrow
            >
              <div>
                <AvatarCell
                  key={new Date().getTime()}
                  id={localStorage.getItem("uid")}
                  imgUrl={param.urlimg + "user/"}
                  ext={"jpg"}
                />
              </div>
            </Tooltip>
          )}
        </div>
        <div className={openMenu ? "nav-group" : "nav-group-resp"}>
          <div className="scroll">
            {props.menu.map((item, index) => {
              return (
                <div key={"nav" + index} className="nav-item">
                  <NavLink
                    aria-current="page"
                    to={`/${item.id}`}
                    id={item.id}
                    onClick={() => handleClick(item.id)}
                  >
                    {showSubMenu === item.id ? (
                      <Icon path={mdi.mdiMenuDown} size="18px" />
                    ) : (
                      <Icon path={mdi.mdiMenuRight} size="18px" />
                    )}
                    <DynamicIcon iconName={item.icon} />
                    {openMenu ? <span> {item.parent}</span> : <></>}
                  </NavLink>
                  {showSubMenu === item.id && (
                    <div className={"sub-nav-group"}>
                      {item.children.map((child, childIndex) => (
                        <Tooltip
                          key={"wrapper" + childIndex}
                          title={!openMenu ? child.child : ""}
                          arrow
                          disableInteractive
                          placement="right"
                        >
                          <div className="sub-nav-item-wrapper">
                            <NavLink
                              key={childIndex}
                              to={`/${item.id}/${child.id}`}
                              className="sub-nav-item"
                              activeClassName="sub-nav-item-selected"
                            >
                              <DynamicIcon iconName={child.icon} />
                              {openMenu ? <span>{child.child}</span> : <></>}
                            </NavLink>
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
            {/* <CustomIconButton
              menuItems={menuItems}
              tooltipTitle="Paramètres du compte"
              avatarText="M"
              icon={<Icon path={mdiAccountCog} size={"22px"} style={{ color: "white", marginBottom: "2px" }} />}
            />
            <Tooltip title="Déconnexion" arrow>
              <div style={{ display: "flex", gap: "10px", alignItems: "center", marginLeft: "16px" }}>
                <IconButton>
                  <Icon
                    path={mdiLogout}
                    color="white"
                    onClick={Logout}
                    size="22px"
                  />
                </IconButton>
                <Typography style={{ color: "white", fontSize: "0.875rem" }}>Déconnexion</Typography></div>
            </Tooltip> */}
            {openMenu ? (
              <>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <CustomIconButton
                    menuItems={menuItems}
                    avatarText="M"
                    icon={
                      <Icon
                        path={mdiAccountCog}
                        size={"22px"}
                        style={{ color: "white", marginBottom: "2px" }}
                      />
                    }
                  />

                  <Typography style={{ color: "white", fontSize: "0.875rem" }}>
                    Paramètres du compte
                  </Typography>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    marginLeft: "14px",
                  }}
                  onClick={Logout}
                >
                  <IconButton>
                    <Icon path={mdiLogout} color="white" size="22px" />
                  </IconButton>
                  <Typography
                    style={{
                      color: "white",
                      fontSize: "0.875rem",
                      cursor: "pointer",
                    }}
                  >
                    Déconnexion
                  </Typography>
                </div>
              </>
            ) : (
              <>
                <CustomIconButton
                  menuItems={menuItems}
                  avatarText="M"
                  tooltipTitle="Paramètres du compte"
                  icon={
                    <Icon
                      path={mdiAccountCog}
                      size={"22px"}
                      style={{
                        color: "white",
                        marginBottom: "2px",
                        marginLeft: "6px",
                      }}
                    />
                  }
                />

                <Tooltip title="Déconnexion" arrow placement="right">
                  <IconButton onClick={Logout}>
                    <Icon
                      path={mdiLogout}
                      color="white"
                      size="22px"
                      style={{ marginLeft: "18px" }}
                    />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
}
