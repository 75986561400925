import React from "react";
import "../style/global.css";
import err from "../img/icons/4042.svg";
import { Button, ThemeProvider } from "@mui/material";
import { theme } from "../style/theme";

export default function ErrorPage() {
  return (
    <ThemeProvider theme={theme}>
      <div className="container-error">
        <div className="error-template">
          <img src={err} alt="err" id="err" />
          {/* //<h1>Page introuvable</h1>       */}
          <div className="error-content">
            <p className="Title-err">
              {
                "Oops, il semble que l'adresse que vous avez introduite est fausse. revenez à l'acceuil."
              }
            </p>
          </div>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => (window.location = "/")}
          >
            Retour à l'accueil{" "}
          </Button>
        </div>
      </div>
    </ThemeProvider>
  );
}
