import React, { useEffect, useState } from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import { Route, Switch, Redirect } from "react-router-dom";
import { ReactComponent as To } from "./../img/to.svg";
import { AlertProvider } from "../contexts/alertContext";
import Empt from "./empty";
import MainMenu from "./main_menu";
import ParamContext from "../contexts/paramContext";
import axios from "axios";
import { LocalizationProvider } from "@mui/x-date-pickers";
// If you are using date-fns v2.x, please import `AdapterDateFns`
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { mdiCog, mdiLockReset, mdiLogout } from "@mdi/js";
import Icon from "@mdi/react";
import UserProfile from "../components/UserProfile";
import AvatarCell from "../components/avatar";
import ResetPwd from "./changepass";
import CustomIconButton from "../components/menu_button";
// import LogoResp from "./../img/logos/logo im.svg";
// import Logo from "./../img/logos/logo";
import LogoResp from "./../img/logos/logo_iman_full.png";

export default function Pages({ param, menu, url }) {
  const [dynamicComponents, setDynamicComponents] = useState([]);
  const version = require("../version.json");
  const Gfunc = require("../Gfunc");
  const defaultPath = window.location.pathname;
  const menuItems = [
    <ResetPwd param={param} />,
    // { divider: true }
  ];

  useEffect(() => {
    const loadDynamicComponents = async () => {
      const dynamicComponentsArray = await Promise.all(
        menu.flatMap((elem) =>
          elem.children.map(async (child) => {
            var module = null;
            try {
              //module = await import(`./content/${child.link}`);
              module = await import(`./content/${child.link}`);
            } catch (e) {
              module = null;
            }
            return {
              parentId: elem.id,
              parent: elem.parent,
              itself: child.child,
              id: `/${elem.id}/${child.id}`,
              component: module ? module.default : null,
              description: child.description,
            };
          })
        )
      );
      setDynamicComponents(dynamicComponentsArray);
    };

    loadDynamicComponents();
  }, [menu]);

  const getDynamicComponentForPath = () => {
    return dynamicComponents.find((obj) => obj.id === defaultPath);
  };

  const Logout = () => {
    axios
      .get(`${param.urlService}disconnect.php`, { headers: Gfunc.getHeader() })
      .then((response) => {
        if (response.data.jData === true) {
          localStorage.removeItem("token");
          localStorage.setItem("isLoged", false);
          localStorage.clear();
          window.location = "/";
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ParamContext.Provider value={param}>
        <AlertProvider>
          {dynamicComponents.length > 0 && (
            <div className="content">
              <MainMenu param={param} menu={menu} />
              <div id="contenu">
                <div id="header">
                  {defaultPath && (
                    <div className="line-container">
                      <span className="pageTitleElem">
                        {getDynamicComponentForPath()?.parent}
                      </span>
                      <div className="horizontal-line"></div>
                      <span className="pageTitleElem">
                        {getDynamicComponentForPath()?.itself}
                      </span>
                      <div className="horizontal-line"></div>
                      <div className="pageTitleElemMain">
                        <span className="pageTitleElem">
                          {getDynamicComponentForPath()?.description}
                        </span>
                      </div>
                    </div>
                  )}

                  <div id="disconnect">
                    {/* <AvatarCell
                      key={new Date().getTime()}
                      id={localStorage.getItem("uid")}
                      imgUrl={param.urlimg + "user/"}
                      ext={"jpg"}
                    /> */}

                    <img
                      src={LogoResp}
                      alt="logo"
                      style={{ height: "45px", marginRight: "16px" }}
                    />

                    {/* <UserProfile /> */}
                    {/* <CustomIconButton
                      menuItems={menuItems}
                      tooltipTitle="Paramètres du compte"
                      avatarText="M"
                      icon={<Icon path={mdiCog} size={"22px"} />}
                    />
                    <Tooltip title="Déconnexion" arrow>
                      <Icon
                        path={mdiLogout}
                        color="var(--secondary-main)"
                        onClick={Logout}
                        size="22px"
                      />
                    </Tooltip> */}
                  </div>
                </div>
                <div id="main-content">
                  <Switch>
                    <Route exact path="/">
                      <Redirect to={url} />
                    </Route>
                    {dynamicComponents.map((dynamicComponent, index) => {
                      return (
                        <Route
                          key={index}
                          path={`${dynamicComponent.id}`}
                          render={(routeProps) => {
                            const DynamicComponent = dynamicComponent.component;
                            if (DynamicComponent)
                              return (
                                <DynamicComponent
                                  {...routeProps}
                                  param={param}
                                />
                              );
                            else
                              return (
                                <Empt
                                  message="Page introuvable"
                                  hideButton={true}
                                />
                              );
                          }}
                        />
                      );
                    })}
                    {/* Redirect to the first child if the dynamic component is not found */}
                    {getDynamicComponentForPath() === undefined &&
                      dynamicComponents.find(
                        (obj) =>
                          obj.parentId === defaultPath.replace(/^\/+/, "")
                      ) && (
                        <Redirect
                          to={`${defaultPath}/${
                            menu.find(
                              (obj) =>
                                obj.id === defaultPath.replace(/^\/+/, "")
                            ).firstChild
                          }`}
                        />
                      )}
                    {/* Fallback route for page not found */}
                    <Route
                      render={() => (
                        <Empt message="Page introuvable" hideButton={true} />
                      )}
                    />
                  </Switch>
                </div>
                <div id="footer">
                  <span>
                    {param.webApp} {version[0].build} &copy; {param.copyright}
                  </span>
                  <span id="powered">
                    powered by {param.company} for {param.licence}
                  </span>
                  <span id="lastMAJ"> Last update: {version[0].date}</span>
                </div>
              </div>
            </div>
          )}
        </AlertProvider>
      </ParamContext.Provider>
    </LocalizationProvider>
  );
}
