//This is an alert context that we can use to display an alert on each page
import React, { createContext, useContext, useEffect, useState } from 'react';
import Alert from '../components/alert';

const AlertContext = createContext();

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error('useAlert must be used within an AlertProvider');
  }
  return context;
};

export const AlertProvider = ({ children }) => {

  const [openMenu, setOpenMenu] = useState(true);
  const [isAlertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState('info'); // Default severity is 'info'

  const showAlert = (message, alertSeverity = 'info') => {
    setAlertMessage(message);
    setSeverity(alertSeverity);
    setAlertVisible(true);
  };

  useEffect(() => {
    let timer;
    if (isAlertVisible) {
      // If alert is visible, set a timer to hide it after 5 seconds
      timer = setTimeout(() => {
        setAlertVisible(false);
      }, 5000);
    }

    // Cleanup function to clear the timer if the component unmounts or alertVisible changes
    return () => {
      clearTimeout(timer);
    };
  }, [isAlertVisible]);


  const hideAlert = () => {
    setAlertVisible(false);
    setAlertMessage('');
    setSeverity('info');
  };

  const handleOpenMenu = (val) => {
    setOpenMenu(val)
  };

  const contextValue = {
    isAlertVisible,
    alertMessage,
    severity,
    openMenu,
    showAlert,
    hideAlert,
    handleOpenMenu
  };

  return (
    <AlertContext.Provider value={contextValue}>
      {/* Render the Alert component at the top level of your app */}
      {isAlertVisible && <Alert />}
      {children}
    </AlertContext.Provider>
  );
};
