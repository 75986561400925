import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import "react-data-table-component";
import './App.css';
import './style/global.css';
import { theme } from "./style/theme";
import ErrorPage from './pages/error';
import Login from './pages/login';
import Pages from './pages/route_pages';
import EmptyPage from './pages/empty';
import { GetIconName } from './js/getMenuIcons';

const version = require("./version.json");

class App extends Component {
  state = {
    menu: [],
    mounted: false,
    loaded: false,
    isLogged: localStorage.getItem('isLoged'),
    param: null,
    path: null
  };

  async componentDidMount() {
    const Gfunc=require('./Gfunc')
    const prm = await Gfunc.getParams();
    if (localStorage.getItem('build') && localStorage.getItem('build') !== version[0].build) {
      localStorage.clear();
      window.location = "/";
      localStorage.setItem('build', version[0].build);
    }
    this.setState({
      mounted: true,
      param: prm
    });
  }

  getMenu = async () => {
    try {
      const Gfunc = require('./Gfunc');
      const transformedData = {};
      const response = await Gfunc.axiosGetAction('getMenu.php','1');
      Gfunc.axiosResponse(response, () => {
        Object.keys(response.data).map((key, index) => {
          const level = response.data[key][2];
          if (level === 1) {
            transformedData[key] = {
              id: key,
              parent: response.data[key][3],
              level: response.data[key][5],
              children: [],
              icon: GetIconName(response.data[key][3])
            };
            transformedData[key]['firstChild'] = null;
          } else if (level === 2) {
            const parentKey = response.data[key][0];
            if(transformedData[parentKey]['firstChild']==null) transformedData[parentKey]['firstChild']=key
            if (transformedData[parentKey]) {
              transformedData[parentKey].children.push({
                id: key,
                link: response.data[key][1],
                child: response.data[key][3],
                description: response.data[key][4],
                levelChild: response.data[key][5],
                icon: GetIconName(response.data[key][3])
              });
              if (response.data[key][5] === 1 || parentKey === '-') {
                transformedData[parentKey]['firstChild'] = key;
              }
            }
          }
          return true;
        });
        const result = Object.values(transformedData);
        var objIndex = result.findIndex((obj => obj.level === 1));
        if(objIndex<0) {objIndex=0;}
        const c = result[objIndex]['children'];
        var objIndexChild = c.findIndex((obj => obj.levelChild === 1));
        if(objIndexChild<0) objIndexChild=0
        if(c[objIndexChild]){
           localStorage.setItem('defaultPath', `/${result[objIndex].id}/${c[objIndexChild].id}`);
           this.setState({ loaded: true, menu: result, path: `/${result[objIndex].id}/${c[objIndexChild].id}` });
          }
        else {
          localStorage.setItem('defaultPath', `/${result[objIndex].id}`);
          this.setState({ loaded: true, menu: result, path: `/${result[objIndex].id}` });
        }
      });
    } catch (error) {
      console.log(error)
      localStorage.clear();
      window.location = "/";
    }
  }

  render() {
    if (!this.state.loaded && this.state.mounted && this.state.isLogged === "true") {
      this.getMenu();
    }

    if (this.state.mounted) {
      return (
        <ThemeProvider theme={theme}>
          <Helmet>
            <title>{(this.state.isLogged !== 'true') ? `${this.state.param.webApp} ${version[0].build}` : localStorage.getItem('title')}</title>
          </Helmet>
          <BrowserRouter>
            <Switch>
              <Route path="/error" component={props => <EmptyPage {...props} />} />
              
              {(this.state.isLogged !== 'true') ?
                <Route exact path="/" component={props => <Login {...props} param={this.state.param} />} />
                :<Pages menu={this.state.menu} param={this.state.param} url={this.state.path} />}
               { (this.state.loaded && this.state.isLogged === "true") ?
                  <Pages menu={this.state.menu} param={this.state.param} url={this.state.path} />
                  :
                  <Route component={props => <ErrorPage />}/>
              }
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      );
    } else {
      return <></>;
    }
  }
}

export default App;
