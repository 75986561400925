import React from 'react';
import { useAlert } from '../contexts/alertContext';
import Alerting from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Icon from '@mdi/react';
import { mdiClose } from "@mdi/js";
import { AlertTitle } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import '../style/theme'
import '../style/global.css'


const Alert = () => {
  const { isAlertVisible, alertMessage, severity, hideAlert, openMenu } = useAlert();
  const theme = useTheme();
  // Helper function to get a suitable title based on severity
  const getTitle = (severity) => {
    switch (severity) {
      case 'success':
        return 'Success';
      case 'error':
        return 'Error';
      case 'warning':
        return 'Warning';
      default:
        return 'Info';
    }
  };

  return (
    <div style={{ overflowY: "visible" }}>
      <Collapse in={isAlertVisible}>
        <Alerting
          className='Alert'
          style={{ maxWidth: (openMenu) ? "calc(100% - 250px)" : "calc(100% - 120px)", zIndex: theme.zIndex.tooltip, color: 'white !important' }}
          variant="filled"
          severity={severity}
          action={
            <IconButton
              className='fellatest'
              aria-label="close"
              color="inherit"
              size="small"
              onClick={hideAlert}
            >
              <Icon path={mdiClose} size="18px" />
            </IconButton>
          }
        >
          <AlertTitle>{getTitle(severity)}</AlertTitle>
          {alertMessage}
        </Alerting>
      </Collapse>
    </div>
  );
};

export default React.memo(Alert);
