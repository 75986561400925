import React, { useEffect, useState } from 'react';

const UserProfile = () => {
  const [nickname, setNickname] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    setNickname(localStorage.getItem('nickname'));
    setName(localStorage.getItem('name'));
  }, []);

  return (
    <div>
      <p className='User-nickname'>  {name}  </p>
      {/* <p className='User-name'>  {nickname}  </p> */}

    </div>
  );
};

export default UserProfile;