import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ErrorPage from './pages/error';
import ErrorBoundary from './error_boundary';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary fallback={<ErrorPage/>}>
        <App />
    </ErrorBoundary>
    
);
